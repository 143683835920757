var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('admin-sidebar'),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',{staticClass:"mt-5 default"},[_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","outlined":"","background-color":"white","label":"Search","prepend-icon":"mdi-magnify","hint":"Enter a tag, make, model, serial or description and press Enter"},on:{"change":function($event){return _vm.loadMyRequestedTags()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"row-clickable",attrs:{"headers":[
							{ text: 'Asset tag', value: 'tag', width: '150px' },
							{ text: 'Printed?', value: 'is_printed', width: '115px' },
							{
								text: 'Purchase date',
								value: 'purchase_date',
								width: '160px',
							},
							{ text: 'Description', value: 'description' },
							{ text: 'Mailcode', value: 'owner.mailcode' },
							{ text: 'Department', value: 'owner.department' },
							{ text: 'Purchaser', value: 'purchase_person' } ],"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemCount,"footer-props":{ 'items-per-page-options': [10, 30, 100] },"show-select":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openEditor},scopedSlots:_vm._u([{key:"item.purchase_person",fn:function(ref){
						var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.purchase_person},on:{"click":function($event){$event.stopPropagation();return _vm.ignore.apply(null, arguments)}}},[_vm._v(_vm._s(item.purchase_person))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length == 0},on:{"click":_vm.printSelected}},[_vm._v("Print selected")]),_c('notifications',{ref:"notifier"}),_c('MyTagsAssetEditor',{ref:"editor",attrs:{"on-save":_vm.onSave}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_vm._v("Administration: "),_c('small',[_vm._v("Tags")])])}]

export { render, staticRenderFns }