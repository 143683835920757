var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-2"},[_c('v-btn',{staticClass:"float-right",staticStyle:{"width":"100%"},attrs:{"color":"primary","large":"","outlined":"","to":"/scan"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-camera")]),_vm._v(" New scan")],1)],1)]),_c('v-data-table',{attrs:{"dense":"","items":_vm.scans,"headers":[
      { text: 'Date', value: 'scan_date', width: '200px' },
      { text: 'Scanned value', value: 'description' },
      { text: 'Scan actions', value: 'actions', width: '120px' } ],"sort-by":"['date']"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"my-1",attrs:{"color":"primary","fab":"","x-small":"","title":"Copy value"},on:{"click":function($event){return _vm.copy(item.scan_value)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"my-1 ml-3",attrs:{"color":"primary","fab":"","x-small":"","title":"Remove scan"},on:{"click":function($event){return _vm.deleteScan(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.value",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" - "),_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(item.device))])]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [(item.asset && item.asset.id)?_c('v-btn',{staticClass:"my-1 mr-3",attrs:{"fab":"","x-small":"","color":"primary","title":"View asset"},on:{"click":function($event){return _vm.openAsset(item.asset)}}},[_c('v-icon',[_vm._v("mdi-dolly")])],1):_vm._e(),(!(item.asset && item.asset.id))?_c('v-btn',{staticClass:"my-1 mr-3",attrs:{"fab":"","x-small":"","color":"primary","title":"Find possible matches"},on:{"click":function($event){return _vm.lookup(item.scan_value)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e(),_vm._v(" "+_vm._s(item.description)+" ")]}}])}),_c('asset-editor',{ref:"editor",attrs:{"onSave":_vm.loadScans}}),_c('notifications',{ref:"notifier"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-10"},[_c('h1',[_vm._v("My Scans")]),_c('p',[_vm._v(" This application works on both desktop and mobile browsers and can be used to scan bar codes. As you scan code, they will be displayed in this screen. ")])])}]

export { render, staticRenderFns }